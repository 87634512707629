import { all } from "redux-saga/effects";

import authSaga from "./authSaga";
import foodDiarySaga from "./foodDiarySaga";
import exerciseSaga from "./exerciseSaga";
import insightSaga from "./insightSaga";
import landingSaga from "./landingSaga";
import progressSaga from "./progressSaga";
import appointmentSaga from "./appointmentSaga";
import healthCoachSaga from "./healthCoachSaga";

function* rootSaga() {
  yield all([
    authSaga(),
    foodDiarySaga(),
    exerciseSaga(),
    insightSaga(),
    landingSaga(),
    progressSaga(),
    appointmentSaga(),
    healthCoachSaga(),
  ]);
}

export default rootSaga;
