import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { useDispatch } from "react-redux";

import Calendar from "src/components/atoms/Calendar";
import BottomSheetSelect from "src/components/atoms/BottomSheetSelect";
import ProgressCard from "src/components/atoms/ProgressCard/ProgressCard";
import TabSwitcher from "src/components/atoms/TabSwitcher";
import ExpandableDivs from "src/components/atoms/Layout/ExpandingLayout";
import SpinnerLoader from "src/components/atoms/Loader/SpinnerLoader";

import { Header } from "src/components/molecules/Header";
import NoExerciseVideoCard from "src/components/molecules/exercise/NoExerciseCard";
import DailyRoutineCard from "src/components/molecules/exercise/DailyRoutineCard";
import ExerciseDescriptionCard from "src/components/molecules/exercise/ExerciseDescriptionCard";
import ExerciseVideoScreen from "src/components/organisms/exercise/ExerciseVideoScreen";
import AddExercise from "src/components/organisms/exercise/AddExercise";
import DailyRoutineScreen from "src/components/organisms/exercise/DailyRoutineScreen";

import ExerciseLog from "src/assets/icons/exercise-log.svg";

import { RootState } from "src/store";
import { removeQueryParamsFromURL } from "src/helpers/common";
import sendMessageToParent from "src/helpers/parentMessageHandler";
import exerciseApis from "src/api/exercise";
import { LOCAL_STORAGE } from "src/constants/common";
import { trackEvent } from "src/analytics";
import { EVENT_NAMES, SCREENS } from "src/analytics/events";

const ExercisePage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { user } = useSelector((state: RootState) => state.auth);
  const isPaidUser = !!user.data.paidUser;

  const [openBottomSheet, setOpenBottomSheet] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(
    searchParams.get("selectedDate")
      ? new Date(searchParams.get("selectedDate") as string)
      : new Date()
  );
  const [activeTab, setActiveTab] = useState<string>(
    searchParams.get("activeTab")
      ? (searchParams.get("activeTab") as string)
      : isPaidUser
      ? "daily-routine"
      : "exercise"
  );
  const [exerciseData, setExerciseData] = useState<any[]>([]);
  const [logData, setLogData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalExcersiseTime, setTotalExcersiseTime] = useState<number>(0);
  const goalId = searchParams.get("goal_id");

  const tabs = [
    { label: "Daily Routine", key: "daily-routine" },
    { label: "Exercise", key: "exercise" },
    { label: "Videos", key: "videos" },
  ];

  const handleDateSelect = (date: Date) => {
    setSelectedDate(date);
  };

  const fetchLoggedExercise = async () => {
    try {
      setLoading(true);
      await fetchGoalAndTarget();
      const response = await exerciseApis.fetchLoggedExercise({
        date: moment(selectedDate).format("YYYY-MM-DD"),
      });
      if (response.code != 1) {
        throw new Error(response.msg || "Internal server error");
      }
      setExerciseData(response?.data || []);
    } catch (error) {
      console.error("Error fetching exercises:", error);
      setExerciseData([]);
    } finally {
      setLoading(false);
    }
  };

  const setExcersiseDuration = useCallback(async (duration: number) => {
    setTotalExcersiseTime(duration);
  }, []);

  const fetchGoalAndTarget = async () => {
    try {
      const logPayload = {
        goal_master_id: goalId,
        date: moment(selectedDate).format("YYYY-MM-DD"),
      };
      const logResponse = await exerciseApis.getGoalAndTarget(logPayload);
      if (logResponse.code != 1 || !logResponse.data) {
        throw new Error(logResponse.msg || "Internal server error");
      }
      setLogData(logResponse.data);
    } catch (error) {
      setLogData(null);
    }
  };

  useEffect(() => {
    removeQueryParamsFromURL(["activeTab", "selectedDate"]);
    fetchLoggedExercise();
  }, [selectedDate]);

  const onPressBack = async () => {
    const queryParams = await localStorage.getItem(LOCAL_STORAGE.QUERY_PARAMS);
    if (queryParams) {
      const parsedParams = JSON.parse(queryParams);
      if (parsedParams?.moduleName.toLowerCase() === "exercise") {
        sendMessageToParent("NAVIGATE_BACK");
      } else {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    checkForDailyRoutine();
  }, []);

  const checkForDailyRoutine = async () => {
    const queryParams = await localStorage.getItem(LOCAL_STORAGE.QUERY_PARAMS);
    if (queryParams) {
      const parsedParams = JSON.parse(queryParams);
      if (parsedParams?.activeTab === "daily") {
        setActiveTab("daily-routine");
        delete parsedParams?.activeTab;
        localStorage.setItem(
          LOCAL_STORAGE.QUERY_PARAMS,
          JSON.stringify(parsedParams)
        );
      }
    }
  };

  const disabledDate = useCallback(
    (current: any) => current && current.valueOf() > Date.now(),
    []
  );

  const isButtonDisabled = !!disabledDate(new Date(selectedDate));
  const handleClick = () => {
    trackEvent(EVENT_NAMES.TAP_ON_DATA_MORPH, {
      date: selectedDate,
    });
    navigate(
      `/exercise/insights?date=${moment(selectedDate ?? new Date()).format(
        "YYYY-MM-DD"
      )}&goal_id=${goalId}`,
      { state: { exerciseDuration: totalExcersiseTime } }
    );
  };

  return (
    <div className="h-screen bg-background">
      <ExpandableDivs
        showExpandBtn={false}
        component1={
          <div>
            <Header
              backButton={true}
              rightButton={!isButtonDisabled}
              title="Exercise"
              rightButtonTitle="Add"
              onClickOfRightBtn={() => setOpenBottomSheet(true)}
              onClickOfBackBtn={onPressBack}
            />
            <Calendar selectedDate={selectedDate} onSelect={handleDateSelect} />
            <ProgressCard
              achievedValue={logData?.achieved_value}
              goalValue={totalExcersiseTime || logData?.goal_value}
              subText="Track your exercise duration here!"
              disabled={!moment().isAfter(selectedDate)}
              onClick={handleClick}
              unitValue="min"
            />
            <TabSwitcher
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
        }
        component2={
          <div>
            {activeTab === "daily-routine" &&
              (isPaidUser ? (
                <DailyRoutineScreen
                  selectedDate={selectedDate}
                  fetchGoalAndTarget={fetchGoalAndTarget}
                  calculateExerciseDuration={setExcersiseDuration}
                />
              ) : (
                <section className="p-4">
                  <DailyRoutineCard
                    videoSrc="https://www.youtube.com/embed/dQw4w9WgXcQ"
                    exerciseType="Push Up"
                    reps={15}
                    sets={3}
                    restPostSets="2 mins"
                    rest="1 min"
                    description="Venenatis id eget tincidunt enim adipiscing amet sollicitudin feugiat. Eros pharetra phasellus urna vitae…"
                    locked={true}
                    exercise={undefined}
                    videoImage={
                      "https://cdn.prod.website-files.com/5ca5fe687e34be0992df1fbe/632aff2c762d08bdfdafa0da_Exercises%20For%20Weight%20Loss.jpg"
                    }
                    setSelectedExercise={() => {}}
                    setDifficultyModal={() => {}}
                    setDifficulty={() => {}}
                  />
                </section>
              ))}
            {activeTab === "exercise" && (
              <section className="p-4">
                {!loading ? (
                  <>
                    {!exerciseData.length ? (
                      <NoExerciseVideoCard
                        onClick={() => {
                          setOpenBottomSheet(true);
                        }}
                        isButtonDisabled={isButtonDisabled}
                      />
                    ) : (
                      exerciseData.map((item, index) => {
                        const start = moment(
                          item.start_time,
                          "YYYY-MM-DD HH:mm:ss"
                        );
                        const end = moment(
                          item.end_time,
                          "YYYY-MM-DD HH:mm:ss"
                        );
                        const duration = end.diff(start, "minutes");
                        const formattedStart =
                          start.format("DD/MM/YY | hh:mm A");
                        return (
                          <div className="mt-4" key={index}>
                            <ExerciseDescriptionCard
                              icon={ExerciseLog}
                              title={item?.exercise || ""}
                              date={formattedStart}
                              duration={duration + " min"}
                              intensity={item?.intensity || ""}
                            />
                          </div>
                        );
                      })
                    )}
                  </>
                ) : (
                  <div className="flex justify-center h-[40vh]">
                    <SpinnerLoader size={35} />
                  </div>
                )}
              </section>
            )}
            {activeTab === "videos" && <ExerciseVideoScreen goal_id={goalId} />}
          </div>
        }
        component2ZIndex={10}
      />

      {/* Bottom Sheet: Main */}
      {openBottomSheet && (
        <BottomSheetSelect
          open={openBottomSheet}
          close={() => setOpenBottomSheet(false)}
        >
          <AddExercise
            setOpenBottomSheet={setOpenBottomSheet}
            goal_id={goalId}
            fetchLoggedExercise={fetchLoggedExercise}
            selectedDate={selectedDate}
          />
        </BottomSheetSelect>
      )}
    </div>
  );
};

export default ExercisePage;
